import React from 'react'
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ReadMore from './ReadMore';
import './post.css'

export default function Post(props) {
   const navigate = useNavigate();
   const {id, title, excerpt, footer} = props;
   const isNotMobile = window.screen.width > 600
   // const nl2br = require('react-nl2br');

  return (
   //   <Link className='link' to={`/singlepost/${id}`}>
      <div className='post'
         onClick={() => {
            navigate(`/singlepost/${id}`)
         }}
      >
         <div className="post-main">
            <h1 className="post-header">{title}</h1>
            <div className='post-body'
            dangerouslySetInnerHTML={{__html: excerpt}}>
            </div>  
         </div>
         { isNotMobile && <ReadMore /> }
         <div className="post-footer">{footer}</div>
      </div>
   //   </Link>
  )
}