import React from 'react'
import './footer.css'

export default function Footer() {
  return (
     <div className='footer'>
      <div>
         <a href={'mailto: tonviska@tonviska.is'}>tonviska@tonviska.is</a>
      </div>
      <div>Hljóðsmárinn ehf, Kt. 601299-4539</div>
      <div>Allur réttur áskilinn</div>
     </div>
  )
}
