import React from 'react'
import { useNavigate } from 'react-router-dom'
import './back-button.css'

export default function BackButton() {
   const navigate = useNavigate()
   const goBack = ()=> {
      navigate('/')
   }
  return (
    <div onClick={goBack}>Til baka</div>
  )
}
