import { useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Header from './Header';
// import Post from './Post';
import SinglePost from './SinglePost';
import Footer from './Footer';
import MainBody from './MainBody';
// import Test from './Test';
const title = 'TónViska'
const subtitle = 'SKRÁNINGARKERFI FYRIR TÓNLISTARSKÓLA'


function App() {
  // const [isLoading, setIsLoading] = useState(true)
  const [postsData, setPostsData] = useState([])

  const fetchPosts = () => {
    // fetch('/newsapi/post')
    fetch('https://new.tonviska.is/newsapi/post')
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        const transformedData = data.data.map(postsTransformedData => {
          return {
            id: postsTransformedData.id,
            title: postsTransformedData.title,
            excerpt: postsTransformedData.excerpt,
            bodytxt: postsTransformedData.bodytxt
          }
        })
        setPostsData(transformedData)
      })
    }

    const getSinglePost = (id) => {
      return postsData.find(
        (post) => post.id === id
      )
    }

  return (
    <div className="App">
      <Header
      title = {title}
      subtitle = {subtitle}
      logo_left = '/img/Viska-logo-2022-2L.png'
      />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={
            <MainBody fetchPosts = {fetchPosts} postsData = {postsData}/>
          } /> 

          <Route path='singlepost/:postId' element={
            <div className='container'>
              <SinglePost getPost = {getSinglePost}/>
            </div>
          } />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Ekkert fannst</p>
              </main>
            }
          />
                
        </Routes>
      </BrowserRouter>
      <Footer />
    
    </div>
  );
}

export default App;
