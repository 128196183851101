import React from 'react'
import { useEffect } from 'react'
import Post from './Post'
export default function MainBody(props) {
   const {fetchPosts, postsData} = props
   useEffect(() => {
      fetchPosts()
      // eslint-disable-next-line
    }, [])

  return (
     <>
     <div className='container'>
        {postsData.map(pData => 
            <Post 
            key = {pData.id} 
            id = {pData.id} 
            title = {pData.title}
            excerpt = {pData.excerpt} />
        )}
      </div>
     </>
  )
}
