import React from 'react'
import { useState } from 'react'
import SubHeader from './SubHeader'

export default function Header(props) {
   const { title, subtitle, logo_left } = props

   const [help, setHelp] = useState(false)

   // const doLogin = () => {
   //    window.location.assign('https://innskraning.island.is/?id=viska.is&authid=d32e2b03-e0c5-4753-9f48-c252291c11e3')
   // }
   const newLogin = () => {
      window.location.assign('https://auth.tonviska.is/?authid=tv')
   }
   return (
      <>
         <div className='headercontainer'>


            <header>

               <div className='site-head'>
                  <img className='logo-left' src={logo_left} alt='' />
                  <div className='title'>{title}</div>
               </div>
               <button className="login-button" onClick={newLogin}>
                  INNSKRÁNING
               </button>

               <button className='login-button' onClick={() => setHelp(!help)}>Hjálp</button>

            </header>
         </div>

         {help &&
            <div style={{ width: '100%', margin: '0 auto', padding: '2rem' }}>
               <h2>Leiðbeiningar til að fá nýtt lykilorð að TónVisku</h2>
               <h2>Bæði fyrir þá sem vilja breyta og þá sem hafa ekki lykilorð</h2>
               <br />
               <ul style={{ fontSize: '1.5rem' }}>
                  <li>Smella á INNSKRÁNING</li>
                  <li>Smella á "Smelltu hér til að fá nýtt"  neðst á innskráningar forminu</li>
                  <li>Slá inn kennitöluna þína (ekki barns) og netfangið sem er skráð fyrir þig hjá skólanum</li>
                  <li>Smella á "Senda"</li>
                  <li>Ef þú hefur ekki fengið tölvupóst innan fárra mínútna skaltu athuga hvort netfangið var rétt slegið inn</li>
                  <li>Ef allt var rétt skaltu athuga hvort pósturinn hefur mögulega farið í ruslpóst hjá þér, sendandi er hjalp@tonviska.is</li>
                  <li>Ef það dugar ekki, prófaðu að bæta hjalp@tonviska.is í tengiliðaskrá hjá þér í póstforritinu</li>
                  <li>Reyna svo ferlið upp á nýtt</li>
                  <li>ATH: eftir 3 misheppnaðar tilraunir þarf að bíða í eina klukkustund áður en prófað er aftur</li>
                  <li>Ef ekkert gengur sendið tölvupóst á hjalp@tonviska.is til að fá aðstoð</li>
               </ul>
               <hr style={{ width: '95vw', marginBottom: 30 }} />
            </div>
         }

         <SubHeader subtitle={subtitle} />

      </>
   )
}
