import React from 'react'
import { useParams } from "react-router-dom";
import BackButton from './BackButton';
import './single-post.css'

export default function SinglePost(props) {
   let params = useParams();
   const {getPost} = props

   const footer = ''

   const post = getPost(parseInt(params.postId, 10))

      return (
      <>
      <div className='singlepost'>
         <div className="post-main">
            <h1 className="post-header">{post.title}
            </h1>
            <hr/>
            <div className='post-body'
            dangerouslySetInnerHTML={{__html: post.bodytxt}}>
            </div>
         </div>
         <div className="backbutton">
            <BackButton />
         </div>
         <div className="post-footer">{footer}</div>
      </div>
      </>
  )
}
